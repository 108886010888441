import React, { Fragment } from 'react';
import { Button, Row, Col, Input } from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import { splashService } from '../../../../services/splash';
// import { Grid, } from "react-bootstrap";
import Placeholder from '../../../../assets/img/slider_placeholder.jpg';
import createAd from '../../../../assets/img/create-new-ad.svg';
import createVid from '../../../../assets/img/browser_video.gif';
import { add } from 'lodash';
var Modal = require('react-bootstrap-modal');

class MyCaretsForm2 extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    // const { id } = this.props.match.params;
    // const { id } = this.props.id;
    const planType = localStorage.getItem('planType')
    this.validatorForm = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
    this.state = {

      user_id: user.data ? user.data.id : null,

      adsList: [],
      plansList: [],
      statusList: [],
      // adsData: OC.CAMPAIGNADS.adsData,
      adsData: [],
      xid: 0,
      submitted: false,
      formType: 'add',
      createNewAd: false,
      createModalForm: false,

      plansSelected: [],
      ad_title: [],
      ad_link: [],
      ad_description: [],

      videoList: [],
      value_Id: [],
      video_url: [],
      video_title: [],
      video_discription: [],
      showAdModal: false,
      selectedAd: null,
      selectedPlanData: null,
      loaderId: 0,
      CaretName: '',
      planType: planType,
      searchTerm: '',

    };

    // this.handleNewAdClick = this.handleNewAdClick.bind(this);
  }



  // ######################################
  handleAdModalClose = () => {
    let idx = this.state.adsData.length - 1 > 0 ? this.state.adsData.length - 1 : 0;
    this.handleRemoveAds(idx)()
    this.setState({ showAdModal: false });
  };


  handleCreateAdModalClose = () => {
    let idx = this.state.adsData.length - 1 > 0 ? this.state.adsData.length - 1 : 0;
    this.handleRemoveAds(idx)()
    this.setState({ createModalForm: false });
  };

  handleSelectAdClick = (idx) => {
    this.setState({ showAdModal: true });
    this.setState({ xid: idx });
  }

  handleVideosSelect = (selectedOptionType) => {
    // console.log("selectedOptionType", selectedOptionType)

    const filtered = this.state.videoList.filter(obj => {
      return obj.value === selectedOptionType['value'];
    });

    if (filtered) {
      let recState = { ...this.state.value_Id };
      recState[this.state.xid] = filtered[0].value;

      let resTitle = { ...this.state.video_title };
      resTitle[this.state.xid] = filtered[0].label;

      let videoUrl = { ...this.state.video_url };
      videoUrl[this.state.xid] = filtered[0].video_raw_url; 

      let resDesc = { ...this.state.video_discription };
      resDesc[this.state.xid] = filtered[0].description;

      // console.log("this.state.resDesc ########## ", resDesc);

      this.setState({ value_Id: recState });
      this.setState({ video_title: resTitle });
      this.setState({ video_discription: resDesc });
      this.setState({ video_url: videoUrl });
    }

    this.setState({ showAdModal: false });
    // this.setState({ adsData: newShareholders }, () => { this.updatePropsData(); });
  };


  // ######################################

  componentDidMount() {
    // console.log('this.props.license_name', this.props.license_name)
    this.getVideosCall(this.props.license_name, this.state.searchTerm);
  }
  handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchTerm });
    this.getVideosCall(this.props.license_name, searchTerm);
  };

  getVideosCall = (val, searchTerm) => {
    splashService.getVideos(val, searchTerm).then(res => {
      // console.log(' videoList:', res.data)
      this.setState({ videoList: res.data });

    });
  }


  handleNext = (e) => {
    e.preventDefault();

    if (this.state.planType === "individual") {
      // console.log("save API call for individual plan", this.props.license_id);
      const { video_title, video_description } = this.props.video;
      const data = {
        license_id: this.props.license_id,
        user_id: this.props.user_id,
        video_title: video_title,
        video_description: video_description,
        sound_id: this.props.sound_id,
        video_ids: this.state.value_Id,

      }
      splashService.createCustumCaret(data).then(res => {
        // console.log("res", res);
        localStorage.removeItem('planType');
        localStorage.removeItem('license_id');
        setTimeout(() => {
          window.location.replace('/Admin/manual-caret');
        }, 1000)
        
      })
    }
    else {
      this.props.setStateOfForm2(true, this.state.value_Id, this.state.planType);
      // localStorage.setItem('adData', JSON.stringify(this.state.adsData));
      // // localStorage.setItem('video_title', JSON.stringify(this.state.video_title));
      // localStorage.setItem('video_description', JSON.stringify(this.state.video_discription));
      // localStorage.setItem('video_url', JSON.stringify(this.state.video_url));
    }

  }


  // #############################################

  updatePropsData() {
    const campaignState = { ...this.state.campaign };
    campaignState['adsData'] = this.state.adsData;
    this.setState({ campaign: campaignState });
  }




  handleAddAds = () => {
    this.setState({
      adsData: [...this.state.adsData.concat([{}])],
    }, () => {
      this.updatePropsData();
      let idx = this.state.adsData.length - 1 > 0 ? this.state.adsData.length - 1 : 0;
      this.handleSelectAdClick(idx)
      // this.setState({ loader: true, loaderId: idx })
    });
  };



  handleRemoveAds = idx => () => {
    // Remove the data associated with the given index
    let newAdTitle = { ...this.state.video_title };
    let newVideoUrl = { ...this.state.video_url };
    let newAdDescription = { ...this.state.video_discription };
    let newPlansSelected = { ...this.state.value_Id };

    delete newAdTitle[idx];
    delete newVideoUrl[idx];
    delete newAdDescription[idx];
    delete newPlansSelected[idx];

    // Re-index the remaining data
    const reindexedAdTitle = {};
    const reindexedVideoUrl = {};
    const reindexedAdDescription = {};
    const reindexedPlansSelected = {};

    Object.keys(newAdTitle).forEach((key, index) => {
      reindexedAdTitle[index] = newAdTitle[key];
      reindexedVideoUrl[index] = newVideoUrl[key];
      reindexedAdDescription[index] = newAdDescription[key];
      reindexedPlansSelected[index] = newPlansSelected[key];
    });

    // Update the state with re-indexed data
    this.setState({
      video_title: reindexedAdTitle,
      video_url: reindexedVideoUrl,
      video_discription: reindexedAdDescription,
      value_Id: reindexedPlansSelected
    });

    // Remove the ad from the adsData array
    this.setState({
      adsData: this.state.adsData.filter((s, sidx) => idx !== sidx)
    }, () => {
      this.updatePropsData();
    });
    this.setState({ loader: false })
  };




  // #############################################
  videoModal(url) {
    this.setState({ video_url: url }, () => {
      this.setState({ openVideoModal: true });
      // console.log('url', this.state.video_url);
    });
  }
  render() {
    const { campaign, formType } = this.state;
    const filterConfig = {
      ignoreCase: true,
      matchFrom: 'start',
    };
    return (
      <Fragment>
        <div className="main_login1 w-100 pb-1 relative">
          <div className="">
            <form>
              {formType === 'edit' &&
                <input type="hidden" name="ad_id" className="form-control" value={campaign.id} />
              }
              <div className="flexElem gap-2 items-center backBtnFix1">
                <h3 className="secHeading m-0">Select Carets Video</h3>
              </div>
              {/* ######## Buttons Here ########### */}
              {this.state.adsData.length === 0 && (
                <div className='text-center mt-xl'>
                  {/* <Button variant="info" disabled={this.state.loader} onClick={this.handleAddAdsCreat}>
                    Create New Ad
                  </Button> */}
                  <img src={createVid} alt={createAd} width={84} className='mt-xl' style={{ cursor: "pointer" }} onClick={this.handleAddAds} />
                  <h2 className='font-boldF text-14 mt-md'>Please click here to choose caret videos <br />and continue with your selection.</h2>
                </div>
              )}
              <div className='innerScrollWrap'>
                <div className="flexElem alignStart gap10p flexColumn w-100">

                  {[...this.state.adsData].map((shareholder, idx) => (
                    <div key={this.state.adsData.length - 1 - idx} className="flexElem alignCenter flexWrap gap10p borderB gap25 pb-md mb-md w-100">

                      <video width="120" height="96" controls
                        key={this.state.video_url[this.state.adsData.length - 1 - idx]}>
                        <source
                          src={this.state.video_url[this.state.adsData.length - 1 - idx]}
                          type="video/mp4"
                        />
                      </video>

                      <div className="flexElem alignCenter gap25">
                        <h4 className="mt-0 mb-0 text-primary text-16 font-bold">Video Description: {this.state.video_discription[this.state.adsData.length - 1 - idx] ? this.state.video_discription[this.state.adsData.length - 1 - idx] : ""}</h4>

                      </div>

                      <div className='ml-auto'>
                        <Button onClick={this.handleRemoveAds(this.state.adsData.length - 1 - idx)} className='btn-danger'> Remove Video </Button>
                      </div>

                    </div>
                  ))}
                </div>
              </div>
              <div className="flexElem ml-auto gap-2 pl-btn-fix" style={{ gap: 10 }}>
                {this.state.adsData.length > 0 && (
                  <Button variant="success"
                    // disabled={this.state.planType === "corporate" ? this.state.adsData.length >= 3: this.state.adsData.length >= 4}
                    disabled={this.state.planType === "corporate" ? this.state.adsData.length >= 6 : this.state.adsData.length >= 6}
                    onClick={this.handleAddAds}>
                    Choose More Videos
                  </Button>
                )}
                <Button
                  type="submit"
                  className="btnDark px-5 font-boldF text-16 w-autoF"
                  // disabled={this.state.planType === "corporate" ? this.state.adsData.length < 3:this.state.adsData.length < 4}
                  disabled={this.state.planType === "corporate" ? this.state.adsData.length < 1 : this.state.adsData.length < 1}
                  onClick={this.handleNext}>
                  Next
                </Button>
              </div>
            </form>
            {/* @@@@@@@@@@@@ Vodeos model Here @@@@@@@@@@@@ */}
            <Modal show={this.state.showAdModal} onHide={this.handleAdModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3 className='flexElem gap-2 items-center'>Select a Carets Video</h3>
                  <div className="ml-auto w-25">
                        <input
                          type="text"
                          placeholder="Search Videos..."
                          value={this.state.searchTerm}
                          onChange={this.handleSearchChange}
                          className="form-control"
                        />
                      </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='flexElem alignCenter spaceBetween mb-20'>
                </div>
                <div className='gridAd'>
                  {this.state.videoList.map((video, index) => (
                    <div className='adWrap' onClick={() => this.handleVideosSelect(video)}>
                      {(index % 4 === 0)}

                      <img src={video.image_url} alt={Placeholder} className='adImage' />
                      <div className='adContent flexElem flexColumn mt-auto gap10p' style={{ wordBreak: 'break-word' }}>
                        <div style={{ marginTop: 'auto' }}>
                          {video.label && (
                            <div>
                              <h6 className='text-primary m-0' style={{ textTransform: 'capitalize', fontSize: 11 }}>video Title:</h6>
                              <h5 className='m-0'>{video.label}</h5>
                            </div>
                          )}
                          <h6 className='text-primary m-0' style={{ textTransform: 'capitalize', fontSize: 11 }}>video Description:</h6>
                          <h5 className='m-0'>{video.description}</h5></div>

                      </div>
                      {(index % 3 === 2 || index === this.state.videoList.length - 1)}
                    </div>
                  ))}
                </div>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal>

          </div>
        </div>
      </Fragment>
    );
  }
}


export default MyCaretsForm2;
