import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import Hls from 'hls.js';
import Progif from "../../../assets/img/progif.gif"
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { RiVideoDownloadFill } from "react-icons/ri";
import { FaVideoSlash } from "react-icons/fa";

import {RiVideoLine} from 'react-icons/ri';
import VideoForm from "./VideoForm";
import Reports from "./Reports";
import Carets from "./Carets";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import DatePicker from "react-datepicker";
import { videoService } from '../../../services/video';
import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
import Animation from '../../../assets/img/animated-download.gif';

import Switch from "react-switch";
var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class VideoListingPage extends Component{
  constructor(props){
  	super(props);
    this.dateValidator = new ReactValidator();
    var today = new Date();
    today.setDate(today.getDate() + 1);

    var lastyear = new Date();
    lastyear.setFullYear(lastyear.getFullYear() - 1);
    var startDate = lastyear.getFullYear() + '-' + (lastyear.getMonth() + 1) + '-' + lastyear.getDate();

    var endDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  	this.state = {
  	  addModalForm  : false,
      editModalForm : false,
      openVideoModal: false,
      openReportModal: false,
      openCaretModal: false,
      video_url:'',
      reportData:[],
      caretData:[],
      selectedVideo:[],
      sorted   : '',
      filtered : '',
      search:{
        fromDate : startDate,
        toDate: endDate,
        is_caret:false,
        is_reported:false},
      pageSize : 10,
      downloading: null,

  	};

  	this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  	this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData  = this.getPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
    this.submitForm= this.submitForm.bind(this);
    this.getUsersCall= this.getUsersCall.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.videoRef = React.createRef();

  };

  videoModal(url) {
    this.setState({video_url:url}, ()=>{
        this.setState({openVideoModal:true});
        console.log('url=> ',this.state.video_url);
    });

    setTimeout(() => {
      this.loadVideo();
    }, 200);

  }

  loadVideo = () => {
    const video = this.videoRef.current;
    const videoUrl = this.state.video_url;
    const fileExtension = videoUrl.split('.').pop().toLowerCase();
    console.log('fileExtension =>', fileExtension);

    console.log('videoUrl', videoUrl);

    // Check if HLS.js is supported
    if(fileExtension === 'm3u8') {


      if (Hls.isSupported()) {
        console.log('HLS.js is supported');
        const hls = new Hls();

        // Add event listener to detect if loading of video source is successful
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.log('Manifest parsed and video source is ready');
        });

        hls.loadSource(videoUrl); // Load the .m3u8 video
        hls.attachMedia(video);

        // Error handling
        hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            console.error('HLS.js error:', data);
          }
        });

        hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
          console.log('Level loaded', data);
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // For Safari (which natively supports HLS)
        video.src = videoUrl;
      } else {
        console.error('Your browser does not support HLS or m3u8');
      }
    }else{
      video.src = videoUrl;
    }
  };

  reportModal(data,selectedVideo) {
    this.setState({reportData:data,selectedVideo:selectedVideo}, ()=>{
        this.setState({openReportModal:true});
        console.log('reportData',this.state.reportData);
    });
  }

  caretModal(data) {
    this.setState({caretData:data}, ()=>{
        this.setState({openCaretModal:true});
        console.log('caretData',this.state.caretData);
    });
  }


  submitForm(formData, formType) {
    if (formType === 'add'){
        videoService.storeVideo(formData).then(res => {
            this.setState({addModalForm:false});
            this.pagination.dataCall();
          });
    }else if (formType === 'edit'){
        videoService.editVideo(formData, this.state.editRec.id).then(res => {
            this.setState({editModalForm:false});
            this.pagination.dataCall();
          });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id)
  {
    videoService.getVideo(id).then(res => {
        console.log(res);
        this.setState({editRec:res.data}, ()=>{
            this.setState({editModalForm:true});
        });
      });
  }

  deleteRecord(recID)
  {
    videoService.deleteVideo(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID)
  {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


getPaginationData(page, filter, sort, pageSize, status)
{
    this.setState({ pageSize: pageSize }, function () {
      videoService.paginationData(page, filter, sort, pageSize, status,this.state.search);
    });
}

updateStatus = recID => (e) => {
// return false;
videoService.updateVideoStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
    response => {
    videoService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
    this.pagination.dataCall();
    }
);
}

updateBlocked = recID => (e) => {
    // return false;
    videoService.blockRecord(recID).then(
        response => {
        videoService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
        this.pagination.dataCall();
        }
    );
    }

  componentDidUpdate(prevProps, prevState)
  {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return videoService.paginationData(data.page, data.filter, data.sort, data.pageSize,this.state.rec_status,this.state.search);
  }


  getUsersCall(){
    videoService.getUsers().then(res => {
        console.log('Users',res);
        this.setState({users:res.data});
      });
  }

componentDidMount() {
    this.getUsersCall();
  }

closeRepoModal = ()=>{

      this.closeModal("openReportModal");
      videoService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
      this.pagination.dataCall();

}
closeCaretModal = ()=>{

    this.closeModal("closeCaretModal");
    videoService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
    this.pagination.dataCall();

}

DateChangeHandler = (value, field) => {
    const temp = cloneDeep(this.state.search);
    temp[field] = value === null ? '' : moment(value).format("YYYY-MM-DD");

    this.setState({ search : temp  }, () => {

        if (this.dateValidator.allValid()) {
            videoService.paginationData(this.props.current_page,'', '', this.state.pageSize, this.state.rec_status,this.state.search);
            this.pagination.dataCall();
        } else {
            this.dateValidator.showMessages();
            this.forceUpdate();
        }
    })
}
dateElement(label, name, value, rules) {
    var selectedDate =  this.state.search[name] !== '' ? new Date(this.state.search[name]) :'';
    const { fromDate, toDate } = this.state.search;
    console.log(value);
    return (
        <div className='form-group fullCalendar' id={name}>
              <label>{label}</label>
              <DatePicker className="form-control calender_icon"  selected={  selectedDate } name={name} onChange={ (event) => this.DateChangeHandler(event, name) } />
              {
                  ( fromDate !== '' && toDate !== '' ) &&
                  this.dateValidator.message(name, value, rules)
              }
        </div>
    );
  }

  handleCaretChange = (checked) => {
    const temp = cloneDeep(this.state.search);
    temp['is_caret'] = checked;
    this.setState({ search : temp  }, () => {
        videoService.paginationData(this.props.current_page,'', '', this.state.pageSize, this.state.rec_status,this.state.search);
        this.pagination.dataCall();
    });
  }
  handleReportChange = (checked) => {
    const temp = cloneDeep(this.state.search);
    temp['is_reported'] = checked;
    this.setState({ search : temp  }, () => {
        videoService.paginationData(this.props.current_page,'', '', this.state.pageSize, this.state.rec_status,this.state.search);
        this.pagination.dataCall();
    });
  }

  downloadVideo = async (videoUrl, fileName, videoId) => {
    try {
      this.setState({ downloading: videoId });

      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the video:', error);
    } finally {
      this.setState({ downloading: null });
    }
  }
  render()
  {
  	const { filtered,editRec,users, downloading } = this.state;
	const { toDate, fromDate } = this.state.search;
    const columns = [
        {
          Header:"Video Listing",
          headerClassName:'headerPagination',
          columns: [
                {
                Header: "Video",
                accessor: "video_url",
                Cell: ({row, original}) => (
                  <div>
                  {(original.caret_processing !==0 || original.is_active === 2) ? <img src={Progif} style={{hight:"25px", width:"30px"}}  alt="processing" title='processing' />:
                    (
                      original.image_url ? (
                        <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.video_url)}>
                          <img style={{ hight: "25px", width: "40px" }} src={original.image_url} />
                        </div>
                      ) : (
                        <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.video_url)}>
                          <RiVideoLine size={40} />
                        </div>
                      )
                    )
                  }
                  </div>
                ),
                // Cell: ({row}) => (
                //     <div style={{cursor:'pointer',fontSize:'20px'}} onClick={()=>this.videoModal(row.video_url)}>
                //         <RiVideoLine />
                //     </div>
                // ),

                className: ""
            },
            {
                Header: "Details",
                accessor: "video_description",
                className: "justifyStart text-left text-wrap",
                headerClassName: "justifyStart",
                minWidth: 220
            },

            {
                Header: "Like",
                accessor: "like_count",
                className: ""
            },
            {
                Header: "Watch",
                accessor: "watch_count",
                className: ""
            },
            {
                Header: "Shares",
                accessor: "share_count",
                className: ""
            },

            {
              Header: "Users",
              accessor: "users",
              Cell: ({value, original}) => (
                <div>
                  {
                    value.map((prop, key)=>{
                      return(<span className="badge badge-success" key={key}>{prop.username?prop.username:prop.email}</span>)
                    })
                  }
                </div>
                ),
              className: "",
              sortable:false,
              minWidth: 180
            },



            {
              Header: "Status",
              accessor: "is_active",
              Cell: ({row}) => (
                  <label className="container-check">
                    &nbsp;
                    <input
                      type="checkbox" name="is_active" className="checkbox" value={row.is_active? 1 : 0} onChange={this.updateStatus(row.id)}  checked={row.is_active?1:0}
                     /><span className="checkmark"></span>
                  </label>
                ),
              className: "",
              sortable:false
            },

            {
                Header: "Blocked",
                accessor: "is_blocked",
                Cell: ({row}) => (
                    <label className="container-check">
                      &nbsp;
                      <input
                        type="checkbox" name="is_blocked" className="checkbox" value={row.is_blocked? 1 : 0} onChange={this.updateBlocked(row.id)}  checked={row.is_blocked?1:0}
                       /><span className="checkmark"></span>
                    </label>
                  ),
                className: "",
                sortable:false
              },

              {
                Header: "Download",
                accessor: "video_export_url",
                Cell: ({ value, original }) => (
                  value ? (
                    <a
                      href={value}
                      onClick={(e) => {
                        e.preventDefault();
                        if (downloading !== original.id) {
                          this.downloadVideo(value, `${original.video_description || 'video'}.mp4`, original.id);
                        }
                      }}
                      style={{
                        pointerEvents: downloading === original.id ? 'none' : 'auto',
                        opacity: downloading === original.id ? 0.5 : 1
                      }}
                    >
                      {downloading === original.id ? <img src={Animation} style={{ hight: "100px", width: "80px" }} alt="downloading" title='downloading' /> : <RiVideoDownloadFill size={30} />}
                    </a>
                  ) : (
                    <span><FaVideoSlash size={30} /></span>
                  )
                )
              },

            {
              Header:"Actions",
              accessor: "id",
              Cell: ({row}) => (
                <div>

                {row._original.videos.length>0 &&
                      <span>
                        <button type='button' className="caretIcon orderDetailsAction"
                        onClick={() => this.caretModal(row._original.videos)}>
                            &nbsp;</button>
                    </span>
                  }

                {row._original.reported.length>0 &&
                      <span>
                        <button type='button' className="flagIcon orderDetailsAction" onClick={() => this.reportModal(row._original.reported,row._original)}>&nbsp;</button>
                    </span>
                  }

                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Video</Popover>}>
                  <span>
                  {
                    <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(row.id)}>&nbsp;</button>
                  }

                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Video</Popover>}>
                  <span>
                  {
                    <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
                  }
                  </span>

                </OverlayTrigger>
                </div>
                ),
              className: "justifyEnd text-right",
              headerClassName: "justifyEnd",
              sortable:false
            }


          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={12}>
                            <div className="secHeading">Video Listing</div>
                          </Col>



                          <Col md={12}>
                            <div className="flexElem flexResponsive mb-lg" style={{"alignItems": "flex-start", "justifyContent": "space-between"}}>
                              <div className="">
                                <OpenModalButton
                                  openModal={HF.openModal.bind(this, "addModalForm")}
                                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn']}
                                  buttonName="Add Video"
                                  classIcon={['fa', 'fa-plus']}
                                />
                              </div>


                            </div>
                          </Col>

                          <Col md={2}>
                                <div className='row flexElem flexResponsive align-items-center' >
                                    <div className="col-md-12" >
                                    {
                                        this.dateElement('Date From', 'fromDate', fromDate!=='' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.search.toDate) }])
                                    }
                                    </div>
                                </div>
                            </Col>
                            <Col md={2} >
                                <div className='row flexElem flexResponsive align-items-center' >
                                    <div className="col-md-12" >
                                    {
                                        this.dateElement('Date To', 'toDate', toDate!=='' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.search.fromDate) }])
                                    }
                                    </div>

                                </div>
                            </Col>
                            <Col md={1} >
                                <div className='row flexElem flexResponsive align-items-center' >
                                    <div className="col-md-12" >
                                    <label>
                                        <span>Caret Videos</span>
                                    </label>
                                    </div>
                                    <div className="col-md-12 mt-sm" >
                                    <Switch onChange={this.handleCaretChange} checked={this.state.search.is_caret} />
                                    </div>
                                </div>
                            </Col>
                            <Col md={1} >
                                <div className='row flexElem flexResponsive align-items-center' >
                                    <div className="col-md-12" >
                                    <label>
                                        <span>Reported Video</span>
                                    </label>
                                    </div>
                                    <div className="col-md-12 mt-sm" >

                                    <Switch onChange={this.handleReportChange} checked={this.state.search.is_reported} />
                                    </div>
                                </div>
                            </Col>
                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'Videos'}
                          defaultSorted={
                            [
                              {
                                id: 'id',
                                desc: true
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No Record found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                          </Col>
                      </Row>
                    </Grid>

                    { users !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Video</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <VideoForm
                                  closeModel={HF.closeModal.bind(this, "addModalForm")}
                                  formTypePro="add"
                                  recPro={OC.VIDEO}
                                  submitForm= { this.submitForm }
                                  componentPro="VideoListing"
                                  users={users}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Add Record Modal End*/}

              		  {/*Edit Record Modal start*/}
                    { editRec &&
                      <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Video</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                          <Card bsClass={["innerCard mb-none"]} content={
                            <VideoForm
                              closeModel={HF.closeModal.bind(this, "editModalForm")}
                              formTypePro="edit"
                              recPro={editRec}
                              submitForm= { this.submitForm }
                              users={users}
                            />
                          } />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }

                    {
                      <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Video</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12" style={{textAlignVertical: "center",textAlign: "center"}}>
                          {/* <video key={this.state.video_url} id="playVid" controls style={{width: 'auto', maxHeight: 'calc(100vh - 150px)'}} ><source src={this.state.video_url} type="video/mp4" /></video> */}
                          <video
                                ref={this.videoRef}
                                controls
                                style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }}
                              />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }


                      <Modal
                      backdrop={'static'}
                      dialogClassName="reportContainer"
                      show={this.state.openReportModal}
                      onHide={HF.closeModal.bind(this, "openReportModal")}
                      aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Reports</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <Reports
                                  closeModel={this.closeRepoModal}
                                  formTypePro="add"
                                  reportData={this.state.reportData}
                                  selectedVideo={this.state.selectedVideo}
                                  submitForm= { this.submitForm }
                                  componentPro="VideoListing"
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>


                      <Modal
                      backdrop={'static'}
                      dialogClassName="caretContainer"
                      show={this.state.openCaretModal}
                      onHide={HF.closeModal.bind(this, "openCaretModal")}
                      aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Videos</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <Carets
                                  closeModel={this.closeRepoModal}
                                  formTypePro="add"
                                  caretData={this.state.caretData}
                                  submitForm= { this.submitForm }
                                  componentPro="CaretListing"
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>

                	</div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
  	);
  };
}
const VideoListing = VideoListingPage;
export default VideoListing;
