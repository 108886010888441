import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import {RiVideoLine} from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import CardForm from 'components/CLM/CardPayment/CardForm';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import { cardService } from '../../../services/card';
import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class CardListingPage extends Component{
  constructor(props){
  	super(props);
    const { id } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem('user'));
  	this.state = {
  	  addModalForm  : false,
      editModalForm : false,
      openVideoModal: false,
      video_url:'',
      sorted   : '',
      filtered : '',
      pageSize : 10,
      userID:user.data ? user.data.id : null,
  	};

  	this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  	this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData  = this.getPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
    this.callFunction= this.callFunction.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.videoModal= this.videoModal.bind(this);
  };


  videoModal(url) {
    this.setState({video_url:url}, ()=>{
        this.setState({openVideoModal:true});
        console.log('url',this.state.video_url);
    });

  }

//   componentDidMount() {
//     this.getUsersCall();
//   }

//   getUsersCall(){
//     cardService.getUsers().then(res => {
//         console.log('Users',res);
//         this.setState({users:res.data});
//       });
//   }

  getDurationsCall(){
    cardService.getDuration().then(res => {
        console.log('Durations',res);
        this.setState({durations:res.data});
      });
  }


  callFunction(formData, formType) {
    if (formType === 'add'){
        this.setState({addModalForm:false});
        this.pagination.dataCall();
    }else if (formType === 'edit'){
        this.setState({editModalForm:false});
        this.pagination.dataCall();
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id)
  {
    cardService.getCard(id).then(res => {
        console.log(res);
        this.setState({editRec:res.data}, ()=>{
            this.setState({editModalForm:true});
        });
      });
  }

  deleteRecord(recID)
  {
    cardService.deleteCard(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID)
  {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


getPaginationData(page, filter, sort, pageSize, status)
{
    this.setState({ pageSize: pageSize }, function () {
      cardService.paginationData(this.state.userID, page, filter, sort, pageSize, status,this.state.search);
    });
}

updateStatus = recID => (e) => {
// return false;
cardService.updateCardStatus(recID, { set_default: e.target.checked ? 1 : 0 }).then(
    response => {
    cardService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
    this.pagination.dataCall();
    }
);
}


  componentDidUpdate(prevProps, prevState)
  {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return cardService.paginationData(this.state.userID, data.page, data.filter, data.sort, data.pageSize,this.state.rec_status,this.state.search);
  }

  render()
  {
  	const { filtered,editRec,durations } = this.state;
    const columns = [
        {
          Header:"Cards Listing",
          headerClassName:'headerPagination',
          columns: [
            // {
            //     Header: "User",
            //     accessor: "user",
            //     Cell: ({value}) => (
            //       <div>
            //        {value.username}
            //       </div>
            //       ),
            //     className: "",
            //     sortable:false
            //   },
            {
                Header: "number",
                accessor: "card_number",
                sortable: false
            },

            {
                Header: "Expiry",
                accessor: "card_expiry",
                sortable: false
            },
            {
              Header: "Default",
              accessor: "set_default",
              Cell: ({row}) => (
                  <label className="container-check">
                    &nbsp;
                    <input
                      type="checkbox" name="set_default" className="checkbox" value={row.set_default? 1 : 0} onChange={this.updateStatus(row.id)}  checked={row.set_default?1:0}
                     /><span className="checkmark"></span>
                  </label>
                ),
              className: "",
              sortable:false
            },


            {
              Header:"Actions",
              accessor: "id",
              Cell: ({row}) => (
                <div>

                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Card</Popover>}>
                  <span>
                  {
                    <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
                  }
                  </span>

                </OverlayTrigger>
                </div>
                ),
              className: "justifyEnd text-right",
              headerClassName: "justifyEnd",
              sortable:false
            }


          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={6}>
                            <div className="secHeading">My Payment Methods</div>
                          </Col>



                          <Col md={6}>
                            <div className="justifyEnd flexElem flexResponsive mb-lg" style={{"alignItems": "flex-start", "justifyContent": "space-between"}}>
                              <div className="">
                                <OpenModalButton
                                  openModal={HF.openModal.bind(this, "addModalForm")}
                                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn']}
                                  buttonName="Add Card"
                                  classIcon={['fa', 'fa-plus']}
                                />
                              </div>


                            </div>
                          </Col>




                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'Cards'}
                          defaultSorted={
                            [
                              {
                                id: 'id',
                                desc: true
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No Record found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                          </Col>
                      </Row>
                    </Grid>

                    {
                      <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Card</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <CardForm
                                  closeModel={HF.closeModal.bind(this, "addModalForm")}
                                  formTypePro="add"
                                  recPro={OC.Card}
                                  user_id={this.state.userID}
                                  callFunction= { this.callFunction }
                                  componentPro="CardListing"
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Add Record Modal End*/}

              		  {/*Edit Record Modal start*/}
                    { editRec &&
                      <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Card</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                          <Card bsClass={["innerCard mb-none"]} content={
                            <CardForm
                              closeModel={HF.closeModal.bind(this, "editModalForm")}
                              formTypePro="edit"
                              recPro={editRec}
                              user_id={this.state.userID}
                              callFunction= { this.callFunction }
                            />
                          } />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }

{
                      <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Ad</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12" style={{textAlignVertical: "center",textAlign: "center"}}>
                          <video key={this.state.video_url} id="playVid" controls style={{width: 'auto', maxHeight: 'calc(100vh - 150px)'}} ><source src={this.state.video_url} type="video/mp4" /></video>
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }





                	</div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
  	);
  };
}
const CardListing = CardListingPage;
export default CardListing;
