import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { appuserService } from '../../services/appuser';
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import MessageCom from '../../layouts/Advertiser/Message';
import caretFooterLogo from '../../assets/img/logo_white.png'
import user from '../../assets/img/user.png'
import { Button } from 'reactstrap';
class VarifyOtp extends Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('email');
    this.state = {
      formData: {
        email: email ||'',
        otp: '',

      },
      submitted: false
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    console.log("asdfkjhasdkf askdfh ");
  }

  componentDidMount() {
    console.log("asdfkjhasdkf askdfh ", this.props);
  }

  handleChange = (e) => {
    const { name, value, type } = e.target;
    const recState = { ...this.state.formData };
    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({formData: recState});
    }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, otp} = this.state.formData;
    if (email, otp) {
        appuserService.passwordResetOTPVerify(this.state.formData).then(res => {
            console.log("passwordResetOTPVerify",res);
            // window.location.replace("/reset-password"); 
            window.location.replace(`/reset-password?email=${encodeURIComponent(email)}&otp=${encodeURIComponent(otp)}`);

          });
    }
  }

  render() {
    const { email, otp } = this.state.formData;
    return (
      <Fragment>
        <div className='fixLoginWrap'>
          <div className="yellowBar" />
          <div className="main_login">
            <MessageCom />
            <p className="text-center logo_container">
              <img className="img-responsive logoImageLogin" src={appBackgourndImg} alt="backGroundImage" /> </p>
            <div className="login_form_container">
              <div className="login_form">
                <h1 className="text-center testrrr text-white"  >Your OTP</h1>
                <form name="form" onSubmit={this.handleSubmit}>
                  <div style={{ display: 'none' }}>
                    <input type="text" placeholder="Email" className="form-control" name="email" value={email} onChange={this.handleChange} />
                    {this.state.submitted && !email &&
                      <div className="help-block">Email is required.</div>
                    }
                  </div>
                  <div>
                    <input type="text" placeholder="OTP" className="form-control" name="otp" value={otp} onChange={this.handleChange} />
                    {this.state.submitted && !otp &&
                      <div className="help-block" style={{ color: 'red' }}>otp is required.</div>
                    }
                  </div>

                  <Button onClick={this.handleSubmit} type='submit' color="primary" block className='roundedFull py-1 mb-1'>Submit</Button>
                  <div className='flexElem alignCenter text-white'>
                    <span className='ml-auto'>
                      {/* <a href='/Advertiser-Login' className='text-info'>Login</a> */}
                      {/* <a href='/' className='text-info'>Login</a> */}
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='copyRights text-white flexElem alignCenter gap10p p-1'>
            <p className='font-weight-light small'>Copyright © 2024, Carets Corporation, All Rights Reserved.</p>
            <img src={caretFooterLogo} alt='footer logo' className='ml-auto' width={64} />
          </div>
        </div>
      </Fragment>
    );
  }
}


export default VarifyOtp;
