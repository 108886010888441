import { SC } from '../helper/ServerCall';

export const corporateService = {
    paginationData,
    storeRequest,
    getLicense,
    editRequest,
    deleteRequest,
    storeCaretSearchData,
    licenseStatus,
    checkPricing,
    requestEmailSendToUser,
    checkRequestStatus
}

function paginationData(user_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.postCall('corporate/data/'+user_id,data);
}

function storeRequest(catData) {
    return SC.postCall('corporate/listing', catData);
}
function storeCaretSearchData(catData) {
    return SC.postCall('v1/storeCaretSearchData', catData);
}
function getLicense(id) {

    return SC.getCall('corporate/listing/' + id);
}

function editRequest(data, splashId) {
    data['license_id'] = splashId;
    return SC.postCall('corporate/listing', data);
}

function deleteRequest(catId) {
    return SC.deleteCall('corporate/listing/' + catId);
}
function licenseStatus(data) {
    return SC.postCall('corporate/licenseStatus', data);
}
function checkPricing(order_id) {
    return SC.postCall('corporate/checkPricing/'+order_id);
}
function requestEmailSendToUser(order_id) {
    return SC.postCall('corporate/requestEmail/'+order_id);
}
function checkRequestStatus(order_id) {
    return SC.postCall('corporate/checkRequestStatus/'+order_id);
}

