import React, { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { cardService } from "../../../services/card";

const stripePromise = loadStripe("pk_test_51ODWiqCyiMpI6AfXhH6zmepIso7FDDrmiRlcYreUfuFrmqlilfrHASfaqu9qhlZ7oAodoHle2ttpTXUah96rwpJ00036vR2T7G"); // Replace with your Stripe Publishable Key

const CardForm = ({ user_id, formTypePro, callFunction, closeModel,ButtonText="Save"  }) => {
    return (
        <Elements stripe={stripePromise}>
            <CardFormContent user_id={user_id} formTypePro={formTypePro} callFunction={callFunction} closeModel={closeModel} ButtonText={ButtonText} />
        </Elements>
    );
};

const CardFormContent = ({ user_id, formTypePro, callFunction, closeModel,ButtonText }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [submitted, setSubmitted] = useState(false);
    const [isDefault, setIsDefault] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.error(error);
            return;
        }

        const formData = {
            user_id,
            stripe_token: paymentMethod.id,  // Send PaymentMethod ID instead of token
            set_default:isDefault
        };

        setSubmitted(true);

        if (formTypePro === "add") {
            cardService.storeCard(formData).then((response) => {
                callFunction(response, formTypePro);
            });
        } else if (formTypePro === "edit") {
            cardService.editCard(formData).then((response) => {
                callFunction(response, formTypePro);
            });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col md={12}>
                    <div className="form-group">
                        <label>Card Details</label>
                        <CardElement className="form-control" />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <div className=''>
                        <label className="container-check"> Default Card?
                        <input
                            type="checkbox"
                            name="set_default"
                            className="checkbox"
                            checked={isDefault}
                            onChange={(e) => setIsDefault(e.target.checked)}
                        />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </Col>
            </Row>




            <Row>
                <Col md={12} className="text-center">
                    <Button type="submit" className="roundedFull btn_login" disabled={!stripe || submitted}>
                        {submitted ? "Processing..." : ButtonText}
                    </Button>

                </Col>
            </Row>
        </form>
    );
};

export default CardForm;
