
import React, { Component, Fragment, createRef } from 'react';
import { CLMUserActions } from "redux/actions/CLM-user-action";
import { Grid, } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, InputGroup, InputGroupText, FormText, Row, Col, CardHeader, Card } from "reactstrap";
// import Footer from "../../FrontEnd/Footer/Footer"
// import Header from "../../FrontEnd/Header/Header"
import SimpleReactValidator from "simple-react-validator";
import { Link, useLocation, withRouter } from "react-router-dom";
import { history } from "helper/history";
import masterCard from "../../../assets/img/sampleCard.svg"
import Swal from "sweetalert2";
import { cartService } from "../../../services/cart";
import { settingsService } from "../../../services/settings";
// import Login from "./CartLogin";
// import Signup from "./CartSignup";
import { cardService } from "../../../services/card";
import Payment from "payment";
import { forEach } from 'lodash';
import { set, toLower } from 'lodash';
import CardForm from '../CardPayment/CardForm';
import { objectsConstants as OC } from 'redux/constant/objects-constant';

var Modal = require('react-bootstrap-modal');
const moment = window.moment;




class CLMRequestCheckout extends Component {
  constructor(props) {
    super(props);
    // var checkLogin = localStorage.getItem('user');
    // if (checkLogin) {
    //   localStorage.removeItem('user');
    // }
    const user = JSON.parse(localStorage.getItem('user'));

    this.validatorForm = new SimpleReactValidator({
      autoForceUpdate: this,
      locale: "en",
    });
    this.state = {
      active: false,
      email: "",
      password: "",
      submitted: false,
      policyModal: "",
      termModal: "",
      formType: "add",
      signupData: [],
      cartList: [],
      cartListItems: [],
      salesContactAmount: 0,
      orderType: "",
      salesTaxAmount: 0,
      CardFormi: false,
      flag: 0,
      useExCard: 0,
      LoginStatus: 0,
      requestAmount: false,
      orderID: this.props.match.params.id,
      userID: user.data ? user.data.id : null,
      formData: {
        terms: "",
        email: "",
        password: "",

      },
      card: {
        user_id: user && user.data ? user.data.id : null,
        card_number: "",
        card_expiry: "",
        card_cvc: "",
        // campaign_id :id,
      },
      ExCardData: [],

    }


    this.signupRef = React.createRef(); // Create a ref for the Signup component
    this.handleChange = this.handleChange.bind(this);
    this.handleCaretChange = this.handleCaretChange.bind(this);
    this.handleRec = this.handleRec.bind(this);
    this.handleRecSelect = this.handleRecSelect.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);

  }

  componentDidMount() {
    const { userID } = this.state;
    this.handleCartListing();
    this.handleSalesTaxAmount();
    this.handleExCardData(userID);
    this.handleSalesContactAmount();

  }
  handleSalesTaxAmount = () => {
    cartService.getSalesTax().then(res => {
      console.log(res);
      this.setState({ salesTaxAmount: res.data && res.data.value });
    })
  }

 callbackfun = (CardData) => {
    console.log("CardData ID",CardData.data.id)
    if(CardData.data.id){
        const card_id = CardData.data.id;
        cardService.storeExistingCard(card_id).then((res) => {
            this.setState({ useExCard: 1 });
            this.handlePayNowClick();
          });
    }
  }

 handleCartListing = () => {
     const userId = this.state.orderID; // Replace with how you get the user ID
     const page = 1; // Set the desired page number
     const filter = ''; // Define filter criteria, if any
     const sort = ''; // Define sorting options, if any
     const pageSize = 20; // Set the desired page size
     const status = ''; // Replace with the desired status
     const search = null; // Add a search query if applicable

     cartService.paginationDataOrderItem(userId, page, filter, sort, pageSize, status, search)
       .then(res => {
         console.log('Cart listing response:', res);
         let cartListItems = res && res.data && res.data.pagination && res.data.pagination.data;
         let cartItems = cartListItems && cartListItems.length > 0 ? cartListItems[0].order : null;

         if (cartListItems) {
               this.setState({
                 cartListItems: cartListItems,
                 cartList:cartItems
               });
           }
         })
       .catch(err => {
         console.error('Error fetching cart listing:', err);
       });
   }



  // ##########
  handleRec(e) {
    const { name, value, type, checked } = e.target;
    const recState = { ...this.state.card };
    let retV = "";

    if (name === "card_number") {
      retV = this.formatCreditCardNumber(value);
    } else if (name === "card_expiry") {
      retV = this.formatExpirationDate(value);
    } else if (name === "card_cvc") {
      retV = this.formatCVC(value);
    } else {
      retV = value;
    }
    // recState[name] = type === "checkbox" ? (checked ? 1 : 0) : retV;
    // this.setState({ card: recState });

    recState[name] = type === "checkbox" ? (checked ? 1 : 0) : retV;
    this.setState({ card: recState }, () => {
      // Call API only when checkbox is checked
      if (name === "set_default" && type === "checkbox" && checked === true) {
        this.setState({ flag: 1 });
      }
    });
  }

  handleRecSelect = (selectedOptionType, info) => {
    console.log("selectedOptionType", selectedOptionType)
    if (info['action'] === 'select-option') {
      let recState = { ...this.state.card };
      recState[info['name']] = selectedOptionType['value'];
      console.log(recState);
      this.setState({ card: recState });
    }
  }

  handleUseExistingCard = (card_id) => {
    cardService.storeExistingCard(card_id).then((res) => {
      this.setState({ useExCard: 1 });
      // console.log('After', res)
      // if (this.props.setStateOfForm3) {
      //   this.props.setStateOfForm3(true, res.data.id);
      // }
    });
  }

  handlePayNowClick = () => {
    if (this.state.useExCard === 1) {
      this.handleLicenseCheck();
    } else if (this.state.flag === 1) {
      const mockEvent = {
        preventDefault: () => { },
      };
      this.handleSubmitForm(mockEvent);
    }
  }

  handleLicenseCheck = () => {
    let deletedItem = [];

    // Map through the cart items and return the promises
    const promises = this.state.cartListItems.map((item) => {
      console.log("Before", item.caret_title);

      let keyword = item && item.caret_title;
      // Make sure checkLicenseAvailability returns a promise
      return cartService.checkLicenseAvailability(keyword).then((res) => {
        console.log("After", res);
        if (res.data === "NO") {
          deletedItem.push(keyword);
          // Ensure deleteRecord is awaited if it is a promise
          return this.deleteRecord(item && item.id);
        }
      });
    });

    // Wait for all promises to resolve
    Promise.all(promises).then(() => {
      if (deletedItem.length > 0) {
        Swal.fire({
          title: "Items Not Available",
          text: `The following items are not available: ${deletedItem.join(", ")}` + ". They have been removed from your cart Items.",
          icon: "warning",
          confirmButtonText: "OK",

        }, () => {
          this.handleCartListing();

        });
      } else {
        this.handleCartListing();
      }
    }).finally(() => {
      // Ensure handleCheckoutClick are called after Promise.all resolves
      this.handleCheckoutClick();
    });
  };

  handleCheckoutClick = () => {
    // console.log('Before', this.state.cartListItems)
    if (this.state.cartListItems.length === 0) {
      Swal.fire({
        title: "Alert",
        text: "Your Cart is Empty",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    } else {

      // let orderType = (this.state.cartList && this.state.cartList.total_amount > Number(this.state.salesContactAmount)) ? 'request': 'order';
      let orderType = 'order';
      this.setState({ orderType: orderType });
      this.executeCheckout(orderType);

    }
  }
  executeCheckout = (orderType) => {
    this.setState({ orderType: orderType });

    cartService.checkoutCart(orderType, this.state.orderID).then((res) => {
      console.log('After', res);
      localStorage.removeItem('guest_token');
      // localStorage.removeItem('user');
      window.location.replace('/CLM/License');
    });
  };
  deleteRecord(recID) {
    cartService.deleteItem(recID).then(res => {

    });
  }
  handleSubmitForm(e) {
    e.preventDefault();
    if (this.validatorForm.allValid()) {
      this.setState({ submitted: true }, function () {
        this.submitForm(this.state.card, this.state.formType);
      });
    } else {
      this.setState({ submitted: false });
      this.validatorForm.showMessages();
      this.forceUpdate();
    }
  }

  submitForm = (formData, formType) => {
    if (formType === 'add') {
      cardService.storeCard(formData).then(res => {
        // this.props.callFunction(this.state.card, this.state.formType);
        this.handleLicenseCheck();

      });
    } else if (formType === 'edit') {
      cardService.editCard(formData, this.state.card.id).then(res => {
        // this.props.callFunction(this.state.card, this.state.formType);
      });
    }
  }

  clearNumber = (value = "") => {
    return value.replace(/\D+/g, "");
  }

  formatCreditCardNumber = (value) => {
    if (!value) {
      return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = this.clearNumber(value);
    let nextValue;

    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 15)}`;
        break;
      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }

    return nextValue.trim();
  }

  formatCVC = (value, prevValue, allValues = {}) => {
    const clearValue = this.clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
      const issuer = Payment.fns.cardType(allValues.number);
      maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
  }

  formatExpirationDate = (value) => {
    const clearValue = this.clearNumber(value);

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  }
  // ########

  handleRadioChange = (e) => {
    this.setState({ active: e.target.checked })
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }


  handleCaretChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    localStorage.setItem("caret_title", value);
  }
  //  %%%%%%%% for signup %%%%%%%%

  // triggerSignup = () => {
  //   // console.log(this.signupRef.current); // Log ref to inspect the component
  //   if (this.signupRef.current) {
  //     // Trigger the form submit programmatically
  //     this.signupRef.current.handleSubmitSignupForCart({
  //       preventDefault: () => { }
  //     });
  //     const mockEvent = {
  //       preventDefault: () => { },
  //       // You can add more properties if needed
  //     };
  //     this.handleSubmitForm(mockEvent);
  //   } else {
  //     console.log("Signup component is not active.");
  //   }
  // };


  handleSalesContactAmount = () => {
    settingsService.getSalesAmount().then((res) => {
      this.setState({ salesContactAmount: res.data && res.data.value });
      // console.log('AAAAAAAAAA=>', res);
    })
  }


  handleExCardData = (userID) => {
    cardService.showExistingCardData(userID).then((res) => {
      // console.log("ExCardData", res.data.pagination.data)
      this.setState({ ExCardData: res.data.pagination.data });
    });
  }
  handleShowCardForm = () => {
    this.setState({ CardFormi: true });
  }

  // %%%%%%%%%%%%%%%%
  render() {
    const { active } = this.state
    const { card, formType, CardFormi, ExCardData, resultsInfo, priceInfo } = this.state;
    const { terms } = this.state.formData;
    const { loggingIn } = this.props;
    return (
      <Fragment>
        {/* <Header /> */}
        <main className='content pl-2-5F pt-2'>
          <div className='px-1'>
            <Row noGutters>
              <Col xs={12} className='px-1'>
                <div className='card borderPurple rounded5p px-2 py-2'>
                  <div className='secHeading mt-0 pb-05 mb-0'>
                    Order Summary
                  </div>
                  <div className='card-body'>
                    <div className='flexElem alignCenter spaceBetween borderB pb-05 mb-1'>
                      <label className='mb-0'>Total Items:</label>
                      <h4 className='m-0'>{this.state.cartList && this.state.cartList.total_items}</h4>
                    </div>
                      <div>
                        <div className='flexElem alignCenter spaceBetween borderB pb-05 mb-1'>
                          <label className='mb-0'>Sub Total:</label>
                          <h4 className='m-0'>${this.state.cartList && this.state.cartList.total_amount}</h4>
                        </div>
                        <div className='flexElem alignCenter spaceBetween borderB pb-05 mb-1'>
                          <label className='mb-0'>Sales Text:</label>
                          <h4 className='m-0'>${
                            (parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) *
                              parseFloat(this.state.salesTaxAmount || 0) / 100)}</h4>
                        </div>
                        <div className='flexElem alignCenter spaceBetween'>
                          <label className='mb-0 font-weight-bold'>Grand Total:</label>
                          <h4 className='m-0'>
                            ${
                              (
                                parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) +
                                (parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) *
                                  parseFloat(this.state.salesTaxAmount || 0) / 100)
                              ).toFixed(2)
                            }
                          </h4>
                        </div>
                      </div>

                  </div>
                </div>
              </Col>
              <Col lg={7}>
                {/* ############ */}
                {
                  (!CardFormi && ExCardData.length > 0 && this.state.useExCard !== 1) &&
                  <button className='btnPurple w-autoF mb-md btn btn-secondary' onClick={() => this.handleShowCardForm()}>Add Card</button>
                }

                {(!CardFormi && ExCardData.length > 0) && (
                  <div className="mt-md">
                    <h4 className='mt-0'>My Cards</h4>
                    {(!CardFormi && ExCardData.length > 0) && (
                      <div className="mt-md">
                        {ExCardData.map((card, index) => (
                          <div key={index} className="paymentCards">
                            <div className="flexElem spaceBetween alignStart gap10p">
                              <img src={masterCard} alt={masterCard} height={20} />
                              <Button className="btnPurple w-autoF mb-md"
                                onClick={() => this.handleUseExistingCard(card.id)}
                              >Use This Card</Button>
                            </div>
                            {/* <br></br> */}
                            {/* <h5>{card.user.name}</h5> */}
                            <div className="flexElem spaceBetween alignStart gap10p">
                              <div className="">
                                {card.card_number}
                              </div>
                              <div className="">
                                <strong>Exp: </strong> &nbsp; {card.card_expiry}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {(this.state.CardFormi || this.state.ExCardData.length === 0) && (
                  <div>
                    <h4 className='mt-0'>Credit card information</h4>
                    <form >
                      {formType === 'edit' &&
                        <input type="hidden" name="card_id" className="form-control" value={card.id} />
                      }
                       <CardForm
                            formTypePro="add"
                            recPro={OC.Card}
                            user_id={this.state.userID}
                            callFunction={this.callbackfun}
                            componentPro="CardListing"
                            ButtonText="Pay Now"
                        />
                      <div className="row">
                        <div className="col-md-12">
                          {/* <div className='text-center'>
                        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
                        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
                      </div> */}
                        </div>
                      </div>
                    </form>
                  </div>



                )}

                {!CardFormi &&(
                    <button color="primary"
                        className="roundedFull btn_login"
                        disabled={
                            this.state.requestAmount
                            ? (this.state.LoginStatus === 1):
                            (!this.state.useExCard && !this.state.flag && this.state.LoginStatus === 1)
                        }
                        onClick={this.state.requestAmount? this.handleLicenseCheck: this.handlePayNowClick}>
                        {this.state.requestAmount ? 'Send Request' : 'Pay Now'}
                    </button>
                )}
              </Col>
              <Col lg={5}>
                {(this.state.LoginStatus === 0 && !this.state.userID) && (
                  <div class="checkSwitch">
                    <input id="checkbox" type="checkbox" checked={active}
                      onChange={this.handleRadioChange} />
                    <label class="button" for="checkbox">
                      <div class="dot"></div>
                    </label>
                    <div class="text">
                      {
                        active ? <h4 className='m-0'>^Carets Login</h4> : <h4 className='m-0'>Already registered? please login!</h4>
                      }
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </main>
        {/* <Footer /> */}
      </Fragment>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn } = state.CLMReducer;
  return {
    loggingIn
  };
}
export default CLMRequestCheckout;
