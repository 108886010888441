import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Grid, Row, Col } from "react-bootstrap";
import { Button, Badge } from "reactstrap";
import Footer from "../FrontEnd/Footer/Footer"
import Header from "../FrontEnd/Header/Header"
import Heart from "../../assets/img/heart.svg"
import Cart from "../../assets/img/cart.svg"
import Swal from "sweetalert2";
import SearchIcon from "../../assets/img/search.svg"
import { licenseService } from "../../services/license";
import { cartService } from "../../services/cart";
import { settingsService } from "../../services/settings";

import { toast } from 'react-toastify';

import { corporateService } from "../../services/corporate";
import axios from "axios";
import { set } from 'lodash';

class Search extends Component {

  constructor(props) {
    super(props);

    var checkLogin = localStorage.getItem('user');
    var caret_title = localStorage.getItem('caret_title');
    // if (checkLogin) {
    //   localStorage.removeItem('user');
    // }
    this.state = {
      value: caret_title ? caret_title : "",
      results: [],
      suggestions: [],
      price: [],
      topSugPrice: [],
      blockKeyword:[],
      salesContactAmount: 0,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

 handleCheckKeyword = () => {
    const { value } = this.state;
    this.setState({blockKeyword:null})
    this.setState({results:[]});
    this.setState({suggestions:[]});
    licenseService.keywordCheckCaret(value).then((res) => {
      if (res.status === 403) {
        console.log('block keyword=> ',res.keyword);
        this.setState({blockKeyword:res.keyword});

      } else {
        if (res.status === 200) {
          this.handleSearchClisification(value);
        }
      }
    })

  }


  handleSubmit(keyword, classification, networth, google_search_volume, instagram, twitter) {


    licenseService.checkCaretAvailability(keyword, classification, networth, google_search_volume, instagram, twitter).then((res) => {

      this.setState({
        results: res,
        price: Array.isArray(res.price) && res.price.length > 0 ? res.price[0] : [],
        topSugPrice: Array.isArray(res.topSuggestion.price) && res.topSuggestion.price.length > 0 ? res.topSuggestion.price[0] : [],
        suggestions: res.suggestions,

      })
    })
  }



  mapDataWithPatterns = (data) => {
    const mappedData = {};

    const patterns = {
      keyword: /keyword/i,
      classification: /classification|class/i,
      net_worth: /net_worth|networth|net worth|worth/i,
      google_search_volume: /google_search_volume|google search volume|search volume/i,
      instagram: /instagram|instagram followers|insta/i,
      twitter: /twitter|twitter followers|twitter/i
    };

    Object.keys(patterns).forEach((standardKey) => {
      // Find the first matching key using the pattern
      for (let key in data) {
        if (patterns[standardKey].test(key)) {
          mappedData[standardKey] = data[key];
          break; // Stop after the first match
        }
      }
    });

    return mappedData;
  };

  componentDidMount() {
    this.handleSalesContactAmount();
    if (this.state.value) {
      this.handleCheckKeyword();
    }
  }

    handleSalesContactAmount = () => {
      settingsService.getSalesAmount().then((res) => {
        this.setState({ salesContactAmount: res.data && res.data.value });
        // console.log('AAAAAAAAAA=>', res);
      })
    }

  handleSearchClisification = async () => {
    const { value } = this.state;
    localStorage.removeItem('caret_title');
    try {
      const response = await axios.get(`https://search.carets.tv/api/generate-text?keyword=${value}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors'

        }
      );

      const mappedData = this.mapDataWithPatterns(response);
      this.handleSubmit(mappedData.keyword, mappedData.classification, mappedData.net_worth, mappedData.google_search_volume, mappedData.instagram, mappedData.twitter);
      setTimeout(() => {
        this.handleStoreSearchData(mappedData);

      },)

    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  handleStoreSearchData = (value) => {
    corporateService.storeCaretSearchData(value).then((res) => {
      // console.log("######### res => ",res);
    })
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleCheckKeyword();
    }
  }

   handleCorporateRequest = (planType, no, title) => {
      Swal.fire({
        title: "Authorized Agent Certification",
        html: `
        <p style="text-align: justify;">
         I certify I am an authorized agent, stakeholder, and/or owner’s representative of the respective organization associated with this ^Caret license.
         I agree to verify and provide any required documentation as deemed necessary by Carets Corporation to confirm identity and corporate association.
         I agree to speak with a ^Carets representative if necessary to facilitate this license agreement and purchase authorization.
         I agree to the ^Caret licensing
          <a href="https://carets.tv/terms" target="_blank" style="color: #3085d6; text-decoration: underline;">
            Terms and Conditions
          </a>.
        </p>
      `,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "I Agree",
        cancelButtonText: "Cancel",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          // Set redirect state to true after confirmation
          this.handleAddToCart(no, title);
          // this.setState({ redirect: true });
          // this.props.history.push({
          //   pathname: "/CLM/CorporateRequestForm",
          //   state: { keyword: this.state.value, plan: planType },
          // });

        }
      });
    };

  // ########### Add to Cart ###################
  handleAddToCart = (no, title) => {

    const { price, value, topSugPrice, topSuggestion, suggestions, results } = this.state;

    // Validate required fields
    if (!price && !topSugPrice && !(suggestions && suggestions.length > 0)) {
      toast.error("No data available for adding to cart.");
      return;
    }

    let sourcePrice = null;

    switch (no) {
      case 1:
        sourcePrice = price;
        break;
      case 2:
        sourcePrice = topSugPrice;
        // keyword = results && topSuggestion && topSuggestion.keyword;
        break;
      case 3:
        sourcePrice = suggestions && suggestions[0] && suggestions[0].price;
        // keyword = suggestions && Array.isArray(suggestions) && suggestions[0] && suggestions[0].keyword;
        break;
      default:
        sourcePrice = null;
        break;
    }

    if (!sourcePrice) {
      toast.error("No valid price data available.");
      return;
    }
    // console.log('sourcePrice', sourcePrice);

    let guestToken = localStorage.getItem("guest_token");
    const { id, stripe_id_one_year, one_year_license } = sourcePrice;


    const cart = {
      caret_title: title ? title : null,
      // caret_title: keyword,
      ...(guestToken && { guest_token: guestToken }),  // Only add guest_token if it exists
      subscription_pricing_id: id,
      subscription_stripe_id: stripe_id_one_year,
      subscription_amount: one_year_license,
    };
    try {
      cartService.addToCart(cart).then((res) => {
        // console.log("######### res => ",res);
        toast.success(res.message);
        if (!guestToken && res.cart && res.cart.guest_token) {
          localStorage.setItem('guest_token', res.cart.guest_token);
        }
        this.handleCartConfirmation(() => {
          window.location.replace("/CLM-cart");
        });
        // window.location.replace("/CLM-cart");
      }).catch((error) => {
        // Handle API error response
        toast.error('Failed to add to cart');
      });
    } catch (error) {
      console.log('Error adding to cart:', error);
    }
  }

   handleCartConfirmation = (redirectToCart) => {
      Swal.fire({
        title: 'Added to Cart!',
        text: 'Would you like to continue to the cart or shop more?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Continue to Cart',
        cancelButtonText: 'More Shopping',
      }).then((result) => {
        if (result.isConfirmed) {
          redirectToCart();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          toast.info('Continue shopping!');
        }
      });
    };


  render() {
    const { results, suggestions, price, topSugPrice,blockKeyword } = this.state;
    // console.log('aaaaaaaa ', results, suggestions)
    return (
      <Fragment>
        <main className='content pl-2-5F pt-2'>
          <div className='container'>
            <div className='flexElem alignCenter gap10p'>
              <div className='clm-search mx-0'>
                <Button style={{ backgroundColor: "transparent", border: "none" }}><img src={SearchIcon} alt='SearchIcon' width={24} /></Button>
                <input type='text'
                  placeholder='Purchase your ^Caret License Today!'
                  name="value"
                  className='form-control'
                  value={this.state.value}
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                />
                <Button className="searchBtn" disabled={this.state.value === ''} onClick={this.handleCheckKeyword}>Search ^Carets</Button>
              </div>
              {/* <Link to={"/CLM-cart"} className="btn btnDark roundedFull w-autoF text-white ml-auto">Go to cart</Link> */}
            </div>
          </div>
          <div className='container'>
            {(blockKeyword && blockKeyword.title)&&
              <Row>
                <Col md={12}>
                  <div className='mainResult'>
                    <div className='py-50 px-2 searchBadge'>
                      Blocked ^Carets License
                    </div>
                    <h4 className='mt-20'>^Carets License: <b>{blockKeyword.title}</b></h4>
                    <p className='mt-20' style={{color:'red'}}>This term violates the terms and conditions an is not available for purchase</p>

                  </div>
                </Col>
              </Row>
            }
            {results && (results.status === "available" || results.status === "unavailable") && (
              <Row>
                <Col xs={12} md={6}>
                  <div className='mainResult'>
                    <div className='py-50 px-2 searchBadge'>
                      Exact Match
                    </div>
                    {/* <h4>{this.state.value}</h4> */}
                    <h4 className='mt-20'>{results.message}</h4>
                    <h4 className='mt-0'>{results.plan_type}</h4>
                    {/* {price.length === 0 ? (
                      <div></div>
                      ) : (
                        <h2 className='price mb-2'>$ {price.one_year_license}</h2>

                      )
                    } */}

                  {(price.length === 0 ||
                    ((price && price.contact_sales) === 1 && (price && price.contact_amount)) ||
                    (price && price.one_year_license) > Number(this.state.salesContactAmount)) ? (
                      <div></div>
                    ) : (
                      <h2 className='price mb-2'>$ {price.one_year_license}</h2>

                    )
                  }

                    {/* {results.plan_type &&
                      <Button color='primary' className='mt-2 px-4 btn btnDark text-white'
                        disabled={results.status === "unavailable"}
                        onClick={() => this.handleAddToCart(1, results.keyword)}
                      >
                        Add to Cart
                      </Button>
                    } */}
                    {results.plan_type &&
                      ((price.length) === 0 ||
                      ((price && price.contact_sales) === 1 && (price && price.contact_amount)) ||
                      (price && price.one_year_license) > Number(this.state.salesContactAmount)) ? (
                      <div>
                        <Button
                          color="primary"
                          className="mt-2 px-4 btn btnDark text-white"
                          disabled={results.status === "unavailable"}
                          onClick={() => this.handleCorporateRequest(results.plan_type, 1, results.keyword)}
                        >
                          Add to Cart
                        </Button>
                      </div>
                    ) : (

                      <Button color='primary' className='mt-2 px-4 btn btnDark text-white'
                        disabled={results.status === "unavailable"}
                        onClick={() => this.handleAddToCart(1, results.keyword)}
                      >
                        Add to Cart
                      </Button>
                    )}

                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='mainResult'>
                    <div className='py-50 px-2 searchBadge'>
                      Exact Match Alternate
                    </div>
                    <h4 className='mt-20'>{results.topSuggestion.keyword}</h4>
                    <h4 className='mt-0'>{results.topSuggestion.plan_type}</h4>
                    <h2 className='price mb-2'>${topSugPrice.one_year_license}</h2>

                    {results.topSuggestion.plan_type &&
                      // <Link to={`/CLM-login?keyword=${this.state.value}`}>

                      <Button color='primary' onClick={() => this.handleAddToCart(2, results.topSuggestion.keyword)} className='mt-2 px-4 btn btnDark text-white'>
                        Add to Cart
                      </Button>
                      // </Link>
                    }
                  </div>
                </Col>
              </Row>
            )}
            {/* ########## SUGGESTIONS ########## */}
            {results && (results.status === "available" || results.status === "unavailable") && (
              <h4>Tailored Other Suggestions</h4>

            )}
            {suggestions && suggestions.length > 0 && (
              suggestions.map((suggestion, index) => (
                <div className='suggestions' key={index}>
                  <div className='suggestionsList flexElem alignCenter gap25'>
                    {/* <Button size="sm">
                  <img src={Heart} alt='Heart' width={14} />
                </Button> */}
                    <h4 className='m-0'>{suggestion.keyword}</h4>
                    <div className='ms-auto flexElem alignCenter gap25'>
                      <h4 className='text-primary m-0'>${suggestion.price.one_year_license}</h4>
                      {suggestion.plan_type &&
                        // <Link to={`/CLM-login?keyword=${this.state.value}`}
                        <dev
                          className="cartFilter" onClick={() => this.handleAddToCart(3, suggestion.keyword)}>
                          <img src={Cart} alt='Cart' width={24} />
                        </dev>
                        // </Link>
                      }
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </main>
      </Fragment >
    );
  }
}

export default Search;
