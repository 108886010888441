import React, { Component } from 'react';
import { advertiserUserActions } from 'redux/actions/advertiser-user-action';
import TargetMarket from 'components/Advertiser/TargetMarket';
import StrategicAdPlacement from './../Advertiser/StrategicAdPlacement';
import videoSample from "../../assets/img/landing/adSample.png"
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Button
} from "react-bootstrap";
import { connect } from 'react-redux';
class HomePage extends Component {
  constructor(props) {
    super(props);
    var checkLogin = localStorage.getItem('user');
    if (checkLogin) {
      localStorage.removeItem('user');
    }
    const params = new URLSearchParams(window.location.search);
    const role = params.get('role');
    this.state = {
      email: '',
      password: '',
      submitted: false,
      role: role? role : null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password, role } = this.state;
    const { dispatch } = this.props;
    // const { role } = this.props;
    // console.log("Role:", role);
    if (email && password) {
      dispatch(advertiserUserActions.login(email, password, role));
    }
  }
  render() {
    const { email, password, submitted } = this.state;
    const { loggingIn } = this.props;
    return (
      <React.Fragment>
        <section className='loginSection'>
          <div className="content container">
            <Row className='heroSection'>
              <Col md={3}>
                <h1 className='text-white text-48 font-weight-bold'>Increase<br />Sales with<br />Carets Ads</h1>
              </Col>
              <Col md={6}>
                <h3 className='text-36 font-weight-bold text-white textCenter'>
                  Advertiser Login
                </h3>
                <div className='fixLoginWrap1'>
                  <div className='main_login_landing'>
                    <div className="login_form_container">
                      <div className="login_form">
                        <form name="form" onSubmit={this.handleSubmit}>
                          <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                            <input type="email" placeholder="Enter Your Email..." className="form-control email_ctrl" name="email" value={email} onChange={this.handleChange} />
                            {submitted && !email &&
                              <div className="help-block">Email is required.</div>
                            }
                          </div>
                          <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                            <input type="password" placeholder="Password" className="form-control password_ctrl" name="password" value={password} onChange={this.handleChange} />
                            {submitted && !password &&
                              <div className="help-block">Password is required.</div>
                            }
                          </div>
                          <div className="form-group">
                            <button className="btn btn_block btnLogin">LOGIN</button>
                            {loggingIn &&
                              <img alt="imageLogin" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            }
                          </div>
                          <div className='flexElem alignCenter text-white mb-1'>
                            <span className=''>Goto
                              <a href='https://carets.tv/' className='text-info'> ^Carets</a>
                            </span>
                            <a href='/send-otp' className='text-info' style={{ marginLeft: 'auto' }}>Forgot Password?</a>
                          </div>
                          <span className='text-white'>Don't Have An Account</span>
                          <div className="form-group">
                            <a href='/Advertiser-signup' className="btn btn_block btnSignup">Create Account</a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3} className='text-center'>
                <h3 className='text-24 text-white mb-1'>
                  Grow your Business with Video Ads.
                </h3>
                <img src={videoSample} alt='videoSample' />
              </Col>
            </Row>
          </div>
        </section>
        <section style={{ marginTop: '2rem' }}>
          <TargetMarket />
        </section>
        <StrategicAdPlacement />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.advertiserReducer;
  return {
    loggingIn
  };
}

const Home = connect(mapStateToProps)(HomePage);
export default Home;
