import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import caretLogo from '../../../assets/img/caretLogo.svg'
import Appstore from '../../../assets/img/appleLogo.svg'
import Playstore from '../../../assets/img/playstore.svg'
import logInOut from '../../../assets/img/log-in-out-black.svg'
import licenserLogin from '../../../assets/img/licenser-login.svg'
import advertiserLogin from '../../../assets/img/advertiser-login.svg'
import Cart from "../../../assets/img/cart.svg"
class HeaderPage extends Component {

  constructor(props) {
    super(props);
  };

  render() {
    const location = document.URL
    const currentUrl = window.location.pathname;
    const showadvlog = currentUrl.includes('/CLM-cart');
    return (
      <header style={{ borderBottom: '1px solid #e8e8e8' }}>
        <div className="yellowBar" />
        <div className='py-50 px-1 bg-whiteF flexElem w-full alignCenter flexWrap'>
          <a href='https://carets.tv/' className='mx-auto ms-md-0'>
            <img src={caretLogo} alt={caretLogo} height={36} className='mb-1 mb-md-0' />
          </a>
          <div className='mx-auto-mobile flexElem gap10p flexWrap-sm'>
          {showadvlog == false &&
            <Link to={"/"} className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100 appBtn alignCenter">
              <img src={advertiserLogin} alt='advertiserLogin' width={18} style={{ filter: 'brightness(4)' }} />
              Login as Advertiser
            </Link>
            }
            <Link to={"/CLM-login"} className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100 appBtn alignCenter">
              <img src={licenserLogin} alt='licenserLogin' width={18} style={{ filter: 'brightness(4)' }} />
              Login as Licenser
            </Link>
            <a href="https://apps.apple.com/us/app/carets/id1571154682" target="_blank" className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100 appBtn">
              <img src={Appstore} alt='Appstore' width={18} />
              <span className='flexElem flexColumn'>
                <small className='text-10 '>Download from</small>
                <span className='text-16'>App Store</span>
              </span>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.carets.tv" target="_blank" className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100 appBtn">
              <img src={Playstore} alt='Playstore' width={18} />
              <span className='flexElem flexColumn'>
                <small className='text-10'>Download from</small>
                <span className='text-16'>Google Play</span>
              </span>
            </a>
            {/* {
              location.includes("/CLM-cart-checkout") &&
              <div className='relativeCard flexElem alignCenter'>
                <img src={Cart} alt='Cart' width={24} />
                <span className='cartCounter'>{this.state.cartList && this.state.cartList.total_items}</span>
              </div>
            } */}
            <a href='#' className='loginSelection' style={{ display: 'none' }}>
              <img src={logInOut} alt='logInOut' width={18} /> LOGIN
              <ul className='loginMenu'>
              {showadvlog == false &&
                <li>
                  <Link to={"/"}>
                    <img src={licenserLogin} alt='licenserLogin' width={18} /> Login as Advertiser
                  </Link>
                </li>
                }
                <li>
                  <Link to={"/CLM-login"}>
                    <img src={advertiserLogin} alt='advertiserLogin' width={18} /> Login as Licenser
                  </Link>
                </li>
              </ul>
            </a>
          </div>
        </div>
      </header>
    );
  }
}


function mapStateToProps(state) {
  const { loading, User, loggedIn } = state.frontEndUserReducer;
  return {
    loading, User, loggedIn
  };
}
const Header = connect(mapStateToProps)(HeaderPage);
export default Header;