import React from 'react';
import {
  Button
} from "react-bootstrap";


import SimpleReactValidator from 'simple-react-validator';
import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { cardService } from '../../../services/card';
import { campaignService } from '../../../services/campaign';
import Payment from "payment";

class CardForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			card: {
				user_id :'',
				card_number :'',
                card_expiry :'',
                card_cvc :'',
			},
            usersList:[],
		    submitted: false,
		    formType: this.props.formTypePro 	|| '',
		};

		this.handleRec = this.handleRec.bind(this);
        this.handleRecSelect = this.handleRecSelect.bind(this);
  		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}



    getUsersCall = async (input) => {
        const res = await campaignService.getUsers(input);
        this.setState({usersList:res.data});
        if(this.state.card.user_id){
            this.getAdsCall(this.state.card.user_id);
        }
        return res.data;
    }

    userOptions = (inputValue) =>
        new Promise ((resolve) => {
            setTimeout(() => {
            resolve(this.getUsersCall(inputValue));
            }, 1000);
        });




	handleRec(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.card };
        let retV = ''

        if (name === "card_number") {
            retV = this.formatCreditCardNumber(value);
        } else if (name === "card_expiry") {
            retV = this.formatExpirationDate(value);
        } else if (name === "card_cvc") {
            retV = this.formatCVC(value);
        }else{
            retV = value;
        }


	    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : retV;
	    this.setState({card: recState});
	}

    handleRecSelect = (selectedOptionType, info) => {
        console.log("selectedOptionType",selectedOptionType)
        if (info['action'] === 'select-option') {
          let recState = { ...this.state.card };
          recState[info['name']] = selectedOptionType['value'];
          console.log(recState);
          this.setState({card: recState});
        }
    }

	handleSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid()) {
			this.setState({submitted: true}, function(){
				this.submitForm(this.state.card, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}

    submitForm = (formData, formType) => {
        if (formType === 'add'){
            cardService.storeCard(formData).then(res => {
				this.props.callFunction(this.state.card, this.state.formType);
              });
        }else if (formType === 'edit'){
            cardService.editCard(formData, this.state.card.id).then(res => {
                this.props.callFunction(this.state.card, this.state.formType);
            });
        }
      }
      clearNumber = (value = "") => {
        return value.replace(/\D+/g, "");
      }

      formatCreditCardNumber = (value) => {
        if (!value) {
          return value;
        }

        const issuer = Payment.fns.cardType(value);
        const clearValue = this.clearNumber(value);
        let nextValue;

        switch (issuer) {
          case "amex":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
              4,
              10
            )} ${clearValue.slice(10, 15)}`;
            break;
          case "dinersclub":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
              4,
              10
            )} ${clearValue.slice(10, 14)}`;
            break;
          default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
              4,
              8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
        }

        return nextValue.trim();
      }

     formatCVC = (value, prevValue, allValues = {}) => {
        const clearValue = this.clearNumber(value);
        let maxLength = 4;

        if (allValues.number) {
          const issuer = Payment.fns.cardType(allValues.number);
          maxLength = issuer === "amex" ? 4 : 3;
        }

        return clearValue.slice(0, maxLength);
      }

      formatExpirationDate = (value) => {
        const clearValue = this.clearNumber(value);

        if (clearValue.length >= 3) {
          return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
        }

        return clearValue;
      }

	render(){
		const { card,formType} = this.state;
		const { durations } = this.props;
		const filterConfig = {
            ignoreCase : true,
            matchFrom  : 'start',
          };
		return(
			<form onSubmit={this.handleSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="card_id" className="form-control"  value={card.id}/>
			    }

                 <div className="row">
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="user_id">Select User<span className="requiredClass">*</span></label>
                            <AsyncSelect
                            cacheOptions
                            defaultOptions
                            name='user_id'
                            loadOptions={this.userOptions}
                            value={this.state.usersList.find((option) => option.value === card.user_id)}
                            onChange={this.handleRecSelect} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="card_number">Number</label>
                            <input
                            type="text"
                            name="card_number"
                            className="form-control"
                            pattern="[0-9\s]{13,19}"
                            value={card.card_number}
                            onChange={this.handleRec} />
                            {this.validatorForm.message('card_number', card.card_number, 'required|card_num')}
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div className='form-group'>
                            <label htmlFor="card_expiry">expiry</label>
                            <input
                            type="text"
                            name="card_expiry"
                            className="form-control"
                            pattern="\d\d/\d\d"
                            value={card.card_expiry}
                            onChange={this.handleRec} />
                            {this.validatorForm.message('card_expiry', card.card_expiry, 'required|card_exp')}
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div className='form-group'>
                            <label htmlFor="card_cvc">CVC</label>
                            <input
                            type="text"
                            name="card_cvc"
                            className="form-control"
                            pattern="\d{3,4}"
                            value={card.card_cvc}
                            onChange={this.handleRec} />
                            {this.validatorForm.message('card_cvc', card.card_cvc, 'required|max:3')}
                        </div>
                    </div>
                </div>

			  <div className="row">

                    <div className="col-md-4">
                        <div className=''>
                            <label className="container-check checkbox_down"> Default Card?
                            <input type="checkbox" name="set_default" className="checkbox" value={card.set_default? 1 : 0} onChange={this.handleRec} checked={card.set_default?1:0} />
                            <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>






			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default CardForm;
